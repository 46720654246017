/* .css-dev-only-do-not-override-1xg9z9n {
    margin-bottom: 0px !important;
} */

span.ant-typography {
    font-size: 16px;
    font-weight: 500;
}

.ant-typography {
    margin-bottom: 0px !important;
}