
.ant-steps-item-active .ant-steps-item-icon {
    background-color: #12B5CB !important;
    border-color: #12B5CB !important;
}

.ant-steps-item-finish .ant-steps-item-icon {
    background-color: #12B5CB !important;
    border-color: #12B5CB !important;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
    color: #fff !important;
}